<template>
  <div>
    <page-header>
      <template v-slot:right>
        <el-button type="primary" @click="handleEdit()">添加角色</el-button>
      </template>
    </page-header>
    <div class="layout-main-body">
      <el-card shadow="never">
        <el-row>
          <el-col :span="24">
            <el-form :inline="true" :model="searchForm">
              <el-form-item>
                <el-input v-model="searchForm.text" placeholder="名称/代码" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button plain @click="handleCurrentChange(1)">查询</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-table
                :data="rows"
                v-loading="loading"
                style="width: 100%">
              <template slot="empty">
                <el-empty description="暂无数据"></el-empty>
              </template>
              <el-table-column type="index" width="55" label="#"></el-table-column>
              <el-table-column prop="name" label="角色名称"></el-table-column>
              <el-table-column prop="dataScope" label="数据权限">
                <template slot-scope="scope">
                  {{ getDataScope(scope.row.dataScope) }}
                </template>
              </el-table-column>
              <el-table-column prop="isSystemDefault" label="系统默认">
                <template slot-scope="scope">
                  {{ scope.row.isSystemDefault ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column prop="creatorName" label="创建人"></el-table-column>
              <el-table-column prop="creationTime" label="创建时间"></el-table-column>
              <el-table-column
                  label="操作"
                  width="200">
                <template slot-scope="scope">
                  <el-button @click="handleRemove([scope.row.id])" type="text" :disabled="scope.row.isSystemDefault">
                    删除
                  </el-button>
                  <el-button @click="handleEdit(scope.row.id)" type="text" :disabled="scope.row.isSystemDefault">编辑
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-dialog
                title="角色编辑"
                :visible.sync="dialogVisible"
                width="40%">
              <el-form :model="editForm" :rules="rules" ref="editForm" label-width="80px">
                <el-form-item label="名称" prop="name">
                  <el-input v-model="editForm.name"></el-input>
                </el-form-item>
                <el-form-item label="数据权限" prop="dataScope">
                  <el-select v-model="editForm.dataScope">
                    <el-option v-for="item in dataScopeList" :key="item.value" :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <!--            <el-form-item label="代码" prop="code">-->
                <!--              <el-input v-model="editForm.code"></el-input>-->
                <!--            </el-form-item>-->
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button plain @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="handleSave" :loading="editLoading">保存</el-button>
              </div>
            </el-dialog>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10,20,50,100]"
                :page-size="size"
                :background="true"
                layout="total, sizes, prev, pager, next"
                :total="total">
            </el-pagination>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import { SysRolesApi } from '@/api'
import { getLabelName, ROLE_DATA_SCOPE } from '@/utils/contants'

const defaultForm = () => {
  return {
    id: '',
    name: '',
    dataScope: 1,
    isSystemDefault: false
  }
}

export default {
  name: 'Role',
  data () {
    return {
      searchForm: {
        text: ''
      },
      editForm: defaultForm(),
      rules: {
        name: [
          {
            required: true,
            message: '请输入角色名称',
            trigger: 'blur'
          }
        ],
        dataScope: [
          {
            required: true,
            message: '请选择数据权限范围',
            trigger: 'change'
          }
        ]
      },
      dialogVisible: false,
      rows: [],
      page: 1,
      size: 10,
      total: 0,
      editLoading: false,
      loading: false,
      currentRoleId: '',
      selectRows: [],
      dataScopeList: ROLE_DATA_SCOPE
    }
  },
  methods: {
    initData () {
      this.handleSearch()
    },
    handleSearch () {
      this.loading = true
      const params = {
        page: this.page - 1,
        size: this.size
      }
      if (this.searchForm.text) {
        params.text = this.searchForm.text
      }
      SysRolesApi.getList(params).then(result => {
        this.rows = result.rows
        this.total = result.total
        this.loading = false
      })
    },
    handleSizeChange (size) {
      this.size = size
      this.handleSearch()
    },
    handleCurrentChange (page) {
      this.page = page
      this.handleSearch()
    },
    handleEdit (id) {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.editForm.resetFields()
        if (id) {
          SysRolesApi.get(id).then(data => {
            Object.assign(this.editForm, data)
          })
        } else {
          this.editForm = defaultForm()
        }
      })
    },
    handleSave () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.editLoading = true
          SysRolesApi.save(this.editForm).then(() => {
            this.editLoading = false
            this.dialogVisible = false
            this.$message.success('保存成功')
            this.handleCurrentChange(1)
          }).catch(() => {
            this.editLoading = false
          })
        } else {
          return false
        }
      })
    },
    handleSelectionChange (val) {
      this.selectRows = val
    },
    handleRemove (id) {
      this.$confirm('确定要删除吗？', '提示', {
        type: 'warning'
      }).then(() => {
        SysRolesApi.remove(id).then(() => {
          this.$message.success('删除成功')
          this.handleCurrentChange(1)
        })
      }).catch(() => {})
    },
    getDataScope (val) {
      return getLabelName(this.dataScopeList, val)
    }

  },
  created: function () {
    this.initData()
  }
}
</script>

<style>
</style>
